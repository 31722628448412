const actionTypes = {
    CHANGE_TAB: "CHANGE_TAB",
    UPDATE_PIN: "UPDATE_PIN",
    ADD_CITY: "ADD_CITY",
    ADD_STATE: "ADD_STATE",
    ADD_CENTERS: "ADD_CENTERS",
    TOGGLE_LOADING: "TOGGLE_LOADING",
    TOGGLE_ERROR: "TOGGLE_ERROR"
}

export default actionTypes;