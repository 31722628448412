import React from 'react';
import './Header.scss';

export default function Header() {
    return (
        <div className="header">
            <img src="/covac-logo192.png" alt="Machaao Logo" />

            {/* <h3>Co-Vac</h3> */}
        </div>
    )
}
